/**
 * Currency Utilities
 */

/**
 * JavaScript floats are not precise enough for financial purposes.
 * To work around this, we import a big number library. Otherwise,
 * float precision errors cause inconsistent results in calculations.
 */
import Big from 'big.js';
export const BigNum = Big;
BigNum.RM = 3 // Always round up

export function formatWithCommas(number) {
  let everyThreeDecimals = /\B(?=(\d{3})+(?!\d))/g

  return BigNum(number).toFixed(2).replace(everyThreeDecimals, ',')
}

export function priceWithCommas(price) {
  return escape(formatWithCommas(price))
}

export function setPrice(el, price) {
  var elements = [].concat(el)

  price = isNaN(price) ? 0 : price

  elements.forEach(function (el) {
    el.innerHTML = `$${priceWithCommas(price)}`
  })
}

export function sum(numbers) {
  return numbers.reduce((total, n) => total.add(n), BigNum(0))
}
